<template>
  <v-card>
    <v-card-title>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="reportsWithPosition"
        :search="search"
        :sort-by="['obtained_marks']"
        :sort-desc="[true]"
        :mobile-breakpoint="0"
    >
      <template v-slot:item.duration="{ item }">
        {{ item.duration | duration }}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | standardDate }}
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import axios from "axios";
import * as moment from "moment";

export default {
  name: "ExamReport",
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Position',
          value: 'position'
        },
        {
          text: 'Name',
          align: 'start',
          value: 'user.name',
        },
        {
          text: 'Obtained Marks',
          value: 'obtained_marks'
        },
        // {
        //   text: 'Time',
        //   value: 'duration',
        // },
        // {
        //   text: 'Submission time',
        //   value: 'created_at',
        // }

      ],
      reports: []
    }
  },
  computed: {
    reportsWithPosition() {
      return this.reports.map((r, index) => ({...r, position: index + 1}))
    }
  },
  methods: {
    initialize() {
      const url = 'exam-reports?id=' + this.$route.params.examId
      axios.get(url).then((response) => {
        this.reports = response.data.exam_reports
      })
    }
  },
  mounted() {
    this.initialize()
  },
  filters: {
    duration(time) {
      let hour = 0;
      let minute = 0;
      let second = time;
      hour = Math.floor(second / 3600)
      second = second % 3600
      minute = Math.floor(second / 60)
      second = second % 60
      return hour + ':' + minute + ':' + second
    },
    standardDate(value) {
      return moment(value).format('D-M-Y hh:mm a')
    }
  }
}
</script>

<style scoped>

</style>